import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import styled, { css, createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      color: #262637;
  }

   ul, li, p, h1, h2 {
    margin: 0;
    padding: 0;
  }

   ul {
     margin-left: 32px;
   }

   li {
     padding-bottom: 8px;
   }
`

const HeaderH1 = styled.h1`
   font-size: 1.5em;
   font-weight: 300;
   padding: 16px;

   @media only screen and (min-width: 768px) {
     padding: 16px 0;
     font-size: 1.8em;
   }
`

const LinkButton = styled.a`
  font-weight: 600;
  border-radius: 4px;
  background-color: #177526;
  color: #fff;
  padding: 0.6em 1.1em;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background-color: #11541c;
  }

  &:active {
    background-color: #11541c;
  }

  &:visited {
    background-color: #177526;
  }
`

export type Messages = {
	title: string;
	toLet: string;
	areaDimensions: string;
	propertyFeatures: string;
	features: [Array<string>, Array<string>],
	mixedCommercial: string;
	highOccupancy: string;
	carParking: string;
	waterfrontDundee: string;
	description: Array<string>;
	lastUpdatedHeader: string;
	lastUpdated: string;
}

const Header = styled.header`
  position: relative;
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0 8px;
  }
`

const InnerHeader = styled.div`
`

const MainContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`

const Container = styled.div`
  @media only screen and (min-width: 1024px) {
    width: 1024px;
  }
`

const Content = styled.div`
  padding: 0 8px;
  margin-top: 16px;
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 2fr 1fr;
  }
`

const Blade = styled.div`
  margin-bottom: 16px;

  ${props => (props.first !== true) && css`
    margin-top: 16px;
    padding-top: 8px;
    border-top: 1px solid #e2e2e2;
  `}
`

const DescriptionBlade = styled.div`
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #e2e2e2;

  p {
    margin-bottom: 8px;
  }
`

const HeaderDetailsH2 = styled.h2`
   margin-bottom: 8px;

   @media only screen and (min-width: 1024px) {
     font-size: 1.1m;
   }
`

const Enquires = styled.div`

  margin-bottom: 16px;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #e2e2e2;

  @media only screen and (min-width: 1024px) {
    padding: 0;
    margin: 0;
    border: 0;
  }
`

const EnquiresSection = styled.div`
  div {
    padding-bottom: 4px;
  }
  @media only screen and (min-width: 768px) {
    box-shadow: 0 0 8px #e2e2e2;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 16px;
  }
`

const HeaderPhotos = styled.div`
   @media only screen and (min-width: 768px) {
     .photo {
       border-radius: 4px;
     }
     display: grid;
     grid-column-gap: 8px;
     grid-template-columns: auto 328px;

     .headerImage {
       height: 100%;
     }
   }
`

const HeaderChildPhotos = styled.div`
   display: none;

   @media only screen and (min-width: 768px) {
     display: grid;
     grid-row-gap: 8px;
   }
`
const PropertyDetails = styled.div`
   @media only screen and (min-width: 768px) {
     display: flex;
   }
`

const PhotoSVG = styled.svg`
 fill: #262637;
`

const PhotoLinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0 6px;
  background-color: white;
  text-decoration: none;
  border: 1px solid #262637;
  border-radius: 4px;
  color: #262637;

  span {
    padding-left: 4px;
  }
`

const PhotosContainer = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;

  @media only screen and (min-width: 768px) {
    right: 16px;
  }
`

const GoogleMapsIFrame = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;

  @media only screen and (min-width: 768px) {
    height: 500px;
  }
`

const YoutubeIFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

const YoutubeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
`;

const PhotosIcon = ({ totalPhotos }: { totalPhotos: number }) => {
	return <PhotosContainer>
		<PhotoLinkButton href="/photos">
			<PhotoSVG xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
				<path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
				<path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
			</PhotoSVG>
			<span>{`${totalPhotos} Photos`}</span>
		</PhotoLinkButton>
	</PhotosContainer>;
}

export const createMainPage = (Messages: Messages) => {
	return ({ totalPhotos }: { totalPhotos: number }) => (
		<>
			<Helmet>
				<title>{Messages.title}</title>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
				<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap" rel="stylesheet" />
				<meta name="Description" content="Dundee Fully-Furnished Ready to use Restuarant to Let.  Located at Units 3 & 4, City Quay, Camperdown Street, Dundee Scotland, DD1"></meta>
			</Helmet>
			<GlobalStyle />
			<MainContainer>
				<Container>
					<Header>
						<HeaderH1>{`${Messages.toLet} - ${Messages.title}`}</HeaderH1>
						<InnerHeader>
							<HeaderPhotos>
								<a href="/photos">
									<StaticImage className="photo headerImage" src="../images/photos/eIMG_5249.JPG" alt="Something?" quality={90} aspectRatio={16 / 9}
										layout="fullWidth" transformOptions={{ trim: 10, cropFocus: 'entropy' }} />
								</a>
								<HeaderChildPhotos>
									<a href="/photos"><StaticImage className="photo" src="../images/photos/cIMG_5246.JPG" alt="Something?" quality={90} aspectRatio={16 / 9}
										transformOptions={{ trim: 10, cropFocus: 'entropy' }}
									/></a>
									<a href="/photos"><StaticImage className="photo" src="../images/photos/iIMG_5248.JPG" alt="Something?" quality={90} aspectRatio={16 / 9}
										transformOptions={{ trim: 10, cropFocus: 'entropy' }}
									/></a>
								</HeaderChildPhotos>
								<PhotosIcon totalPhotos={totalPhotos} />
							</HeaderPhotos>
						</InnerHeader>
					</Header>
					<Content>
						<div>
							<Blade first={true}>
								<HeaderDetailsH2>{Messages.propertyFeatures}</HeaderDetailsH2>
								<PropertyDetails>
									<ul>
										{Messages.features[0].map(feature => <li key={feature}>{feature}</li>)}
									</ul>
									<ul>
										{Messages.features[1].map(feature => <li key={feature}>{feature}</li>)}
									</ul>
								</PropertyDetails>
							</Blade>
							<DescriptionBlade>
								<HeaderDetailsH2>{Messages.descriptionHeading}</HeaderDetailsH2>
								{Messages.description.map(description => <p>{description}</p>)}
							</DescriptionBlade>
							<Blade>
								<HeaderDetailsH2>{Messages.videoWalkthroughHeader}</HeaderDetailsH2>
								<YoutubeContainer>
									<YoutubeIFrame src="https://www.youtube.com/embed/A4sESZBx1ek" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
								</YoutubeContainer>
							</Blade>
							<Blade>
								<HeaderDetailsH2>{Messages.locationHeader}</HeaderDetailsH2>
								<GoogleMapsIFrame
									loading="lazy"
									src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCJleHg-uG6c0GjnWyaFel_L89ctHsLCoU&q=City+Quay+Camperdown+St+Dundee+DD1+3JA+UK" />
								<ul>{Messages.locationPoints.map(point => <li>{point}</li>)}</ul>
							</Blade>
							<DescriptionBlade>
								<HeaderDetailsH2>{Messages.cityQuayHeading}</HeaderDetailsH2>
								{Messages.cityQuayDescription.map(description => <p>{description}</p>)}
							</DescriptionBlade>
							<Blade>
								<HeaderDetailsH2>{Messages.lastUpdatedHeader}</HeaderDetailsH2>
								<p>{Messages.lastUpdated}</p>
							</Blade>
						</div>
						<Enquires>
							<EnquiresSection>
								<HeaderDetailsH2>{Messages.enquiresHeader}</HeaderDetailsH2>
								<div>Paul Yu</div>
								<div><LinkButton href="mailto:enquires@3-4-cityquay.co.uk">Email enquires@3-4-cityquay.co.uk</LinkButton></div>
								<div><LinkButton href="tel:01316188834">Tel: 0131 618 8834</LinkButton></div>
							</EnquiresSection>
						</Enquires>
					</Content>
				</Container>
			</MainContainer>
		</>
	)
}
