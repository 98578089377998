import * as React from "react";
import styled, { createGlobalStyle } from 'styled-components';
import { graphql, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const GlobalStyle = createGlobalStyle`
  body {
      margin: 0;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      color: #262637;
  }

  h1,h2 {
    margin: 0;
    padding: 0;
  }
`

const PhotoGrid = styled.div`
  box-shadow: 8px 8px 8px rgba(0,0,0,0.21);
  padding: 0 16px;
  border-top: none;

  div {
    margin-top: 8px;
  }

  .gatsby-image-wrapper {
    border-radius: 6px;
  }

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: column;
	padding: 0 16px;

    .selected .gatsby-image-wrapper {
       outline: 3px solid #03b538;
    }

    .gatsby-image-wrapper {
      cursor: pointer;
    }
  }
`

const Navigation = styled.div`
  padding: 8px;
  top: 0px;
  background-color: white;
  width: 100%;

  @media only screen and (min-width: 768px) {
    position: relative
  }
`

const ChromeLessButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.2em;
`

const PhotoContainer = styled.div`
  max-width: 960px;
  position: sticky;
  top: 10px
`

const Container = styled.div`
  position: relative;
  border-top: 1px solid #ccc;

  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: calc(150px + 16px + 16px) 1fr;
  }
`

const SelectedContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
      display: block;
      position: sticky;
      top: 10px;
      padding-left: 16px;
      padding-top: 16px;
  }
`

const SelectedPhoto = ({ image }: { image: any }) =>
	<PhotoContainer>
		<GatsbyImage image={image} alt="nothing" />
	</PhotoContainer>;

const Photos = ({ data }) => {
	const photos = data.allFile.edges;
	const initialPhoto = getImage(photos[0].node);
	const [selectedImage, setSelectedImage] = React.useState(initialPhoto);

	const onClick = () => navigate(-1);

	const PhotoElements = photos.map((item, index) => {
		const image = getImage(item.node);
		return <div key={index} onClick={() => setSelectedImage(image)} className={selectedImage === image ? 'selected' : ''}>
			<GatsbyImage image={image} alt="nothing" />
		</div >
	});

	return <>
		<GlobalStyle />
		<Navigation>
			<ChromeLessButton onClick={onClick}>&#8592; Back</ChromeLessButton>
		</Navigation>
		<Container>
			<PhotoGrid>
				{PhotoElements}
			</PhotoGrid>
			<SelectedContainer>
				<SelectedPhoto image={selectedImage} />
			</SelectedContainer>
		</Container>
	</>
}
export default Photos;

export const pageQuery = graphql`
query Photos {
  allFile(filter: {extension: {eq: "jpg"}, relativePath: {regex: "/photo/"}}) {
    edges {
      node {
        childImageSharp {
           gatsbyImageData(breakpoints:[150, 1024], quality: 90, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
        name
      }
    }
  }
}
`
