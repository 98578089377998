import * as React from "react"
import { graphql, navigate } from "gatsby";
import { Messages } from '../template/messages.en';
import { createMainPage } from '../template/createMainPage';

const EnPage = createMainPage(Messages);

// const getRedirectLanguage = () => {
//     if (typeof navigator === `undefined`) {
//         return "en";
//     }

//     const lang = navigator && navigator.language && navigator.language.split("-")[0];
//     if (!lang) return "en";

//     switch (lang) {
//         case "cn":
//             return "cn";
//         default:
//             return "en";
//     }
// };

const IndexPage = ({ data }) => {
    const totalPhotos = data.allFile.totalCount;
    return <EnPage totalPhotos={totalPhotos} />
};

export default IndexPage;

export const pageQuery = graphql`
query TotalPhotos {
  allFile(filter: {relativePath: {regex: "/photos/"}}) {
    totalCount
  }
}
`

