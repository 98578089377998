export const Messages = {
    title: "[CN] Units 3 & 4, City Quay, Camperdown Street, Dundee Scotland, DD1",
    toLet: "[CN] To Let",
    areaDimensions: "[CN] 4,127ft² (383.41m²)",
    propertyFeatures: "[CN] Property Features",
    features: [
        [
            "[CN] Fully-Equipped Restaurant To Let",
            "[CN] Fully-Equipped Kitchen With 3 Chest Freezers and 2 Walk-In Cold-Rooms",
            "[CN] Located on the Dundee City Quay",
            "[CN] On-Site Car Parking Is Available",
            "[CN] Area 4,127ft² (383.41m²)",
        ],
        [
            "[CN] Waterfront Views",
            "[CN] Short-Walking Distance From Dundee City Centre",
            "[CN] Fully-Fitted, Ready To Lease",
            "[CN] Lease Terms on Enquire",
        ]
    ],
    locationHeader: "Location",
    locationPoints: [
        '[CN] 5 minute walk from the Dundee Bus Station.',
        '[CN] 10 minute walk from the Dundee Railway Station.',
        '[CN] 10 minute walk from the Dundee V&A Museum.'
    ],
    descriptionHeading: "[CN] Description",
    description: [
        `[CN] The restaurant comprises of a single-storey retail unit within the heart of the City Quay development.
It has the benefit of an attractive glazed display frontage as well as glazed windows and doors running the full length of its southern elevation which overlooks the waterfront.`,
        `[CN] Previously run as a successful chinese buffet for 17 years, comes with a fully-equipped kitchen catering to asian cuisine, that includes 3 chest freezers and 2 walk-in coldroom.
Furnished dining area which includes tables and chairs, equipment to serve and preserve hot & cold food and an equipped bar.`,
        `[CN] This is a great opportunity for an enterprising chef experienced in modern asian cuisine to enter the catering business for a relatively small investment, and a good reason to let.
Terms of lease and Rent negotiable.`
    ],
    lastUpdatedHeader: "[CN] Last Updated",
    cityQuayHeading: "[CN] City Quay",
    cityQuayDescription: [
        "[CN] Located around the former Victoria Quay on Dundee's harbour, City Quay is a retail, leisure and hotel development which together represents a £20 million revitalization of the area. Located on the north side of the harbor and opened in 2002, the eponymous shopping complex occupies former warehouses and borders the harbor itself. The 155-room City Quay Hotel is located to the west, next to Dundee Customs House and Harbor Chambers and the former main harbor entrance. Housing development was taking place to the east, adjacent to Camperdown Dock.",
        "[CN] The development includes a harbor with the frigate Unicorn, launched in 1824, and the former North Carr Lighthouse, both permanently moored at Victoria Dock."
    ],
    videoWalkthroughHeader: "[CN] Video Walkthrough",
    enquiresHeader: "[CN] Enquires",
    lastUpdated: "[CN] 24/09/2022",
    downloadButtonLabel: "[CN] Download Brochure"
}

