export const Messages = {
    title: "Units 3 & 4, City Quay, Camperdown Street, Dundee Scotland, DD1",
    toLet: "To Let",
    areaDimensions: "4,127ft² (383.41m²)",
    propertyFeatures: "Property Features",
    features: [
        [
            "Restaurant To Let",
            "Partially-Equipped Kitchen With 2 Chest Freezers",
            "Located on the Dundee City Quay",
            "On-Site Car Parking is Available",
            "Area 4,127ft² (383.41m²)",
        ],
        [
            "Waterfront Views",
            "Short-Walking Distance From Dundee City Centre",
            "Ratable Value: £38,200",
            "Ready To Lease. Lease Terms on Enquire",
        ]
    ],
    locationHeader: "Location",
    locationPoints: [
        '5 minute walk from the Dundee Bus Station.',
        '10 minute walk from the Dundee Railway Station.',
        '10 minute walk from the Dundee V&A Museum.'
    ],
    descriptionHeading: "Description",
    description: [
        `The restaurant comprises of a single-storey retail unit within the heart of the City Quay development.
It has the benefit of an attractive glazed display frontage as well as glazed windows and doors running the full length of its southern elevation which overlooks the waterfront.`,
        `Previously run as a successful chinese buffet for 17 years, comes with a partially-equipped kitchen catering to asian cuisine, that includes 2 chest freezers, wok-station and atmospheric steamer.
Furnished dining area which includes tables and chairs, equipment to serve and preserve hot & cold food and an equipped bar.`,
        `This is a great opportunity for an enterprising chef experienced in modern asian cuisine to enter the catering business for a relatively small investment, and a good reason to let.
Terms of lease and Rent negotiable.`
    ],
    cityQuayHeading: "City Quay",
    cityQuayDescription: [
        "Located around the former Victoria Quay on Dundee's harbour, City Quay is a retail, leisure and hotel development which together represents a £20 million revitalization of the area. Located on the north side of the harbour and opened in 2002, the eponymous shopping complex occupies former warehouses and borders the harbour itself. The 155-room City Quay Hotel is located to the west, next to Dundee Customs House and Harbour Chambers and the former main harbour entrance. Housing development was taking place to the east, adjacent to Camperdown Dock.",
        "The development includes a harbour with the frigate Unicorn, launched in 1824, and the former North Carr Lighthouse, both permanently moored at Victoria Dock."
    ],
    lastUpdatedHeader: "Last Updated",
    videoWalkthroughHeader: "Video Walkthrough",
    enquiresHeader: "Enquires",
    lastUpdated: "4/10/2022",
    downloadButtonLabel: "Download Brochure"
}

